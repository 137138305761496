
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: #0096D6 ;
    }

    .a::after {
        content: "";
        position: absolute;
        top: 10px;
        right: -10px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #f90;
        display: block;
        width: 0;
    }

    /* .container {
        width: flex;
        margin-top: 3em;
      } */
      #accordion .panel {
        border-radius: 0;
        border: 0;
        margin-top: 0px;
      }
      #accordion a {
        display: block;
        font-size: large;
        padding: 10px 15px;
        border-bottom: 1px solid #d9d2d2;
        text-decoration: none;
      }
      #accordion .panel-heading a.collapsed:hover,
      #accordion .panel-heading a.collapsed:focus {
        background-color: lightslategrey;
        color: white;
        transition: all 0.2s ease-in;
      }
      #accordion .panel-heading a.collapsed:hover::before,
      #accordion .panel-heading a.collapsed:focus::before {
        color: white;
      }
      #accordion .panel-heading {
        padding: 0;
        border-radius: 0px;
        
      }
      #accordion .panel-heading a:not(.collapsed) {
        color: white;
        background-color: #655e5e;
        transition: all 0.2s ease-in;
      }
      
      /* Add Indicator fontawesome icon to the left */
      #accordion .panel-heading .accordion-toggle::before {
        font-family: 'FontAwesome';
        content: '\f00d';
        float: left;
        color: white;
        font-weight: lighter;
        transform: rotate(0deg);
        transition: all 0.2s ease-in;
      }
      #accordion .panel-heading .accordion-toggle.collapsed::before {
        color: #444;
        transform: rotate(-135deg);
        transition: all 0.2s ease-in;
      }
      
    