.btnStylePrimary {
    -webkit-border-radius: 0px 8px 0px 8px;
    -moz-border-radius: 0px 8px 0px 8px;
    border-radius: 0px 8px 0px 8px;
    border: 0px;
    background-color: #0096D6;
    font-size: small;
    font-weight: bold;
    font-family: HPSimplified,Arial,Verdana,Helvetica,Sans-serif;
    color: #FFFFFF;
    height: 36px;
    text-align: center;
    width:100px;
    max-width: 100%;
    vertical-align: middle;
    padding: 0px;
    outline:none; 
  }
 
  .my-modal .modal-content {
    background-color:#f8f9fa;
  }

  .menu {
    background: #ffffff;
    /* height: 100vh; */
    /* width: 300px; */
    top: 35px;
    right: 7%;
    opacity: 0.2;
    position: absolute;
    display: none;
    transition: all 0.25s ease;
    transform: translateX(-50%);
    z-index: 99;
   
  }
  
  .active {
    opacity: 1;
    display: block;
    transition: all 0.25s ease;
    transform: translateX(0);
   
  }

  .cart-button{
    height: 26px;
    vertical-align: -webkit-baseline-middle;
    -moz-border-radius: 0px 8px 0px 8px;
    -webkit-border-radius: 0px 8px 0px 8px;
    border-radius: 0px 8px 0px 8px;
    background-color: #0096D6;
    box-shadow: none;
    color:#ffff !important;
    text-shadow: none;
    font-size: 14px;
    outline:none; 
    padding: 0px;
    border-color: none !important;
}

.form-control{
   width: 100%;
     /* padding: .5em; */
     display: flex;
     flex-wrap: wrap;
}