
#container{
min-height: 100%;
}

#main{
  overflow:auto;
  padding-bottom: 100px;
}

/* #header{
    height: 100px;
    line-height: initial !important;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 100000;
} */

body {
  margin: 0;
  font-family: 'HP Simplified';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



