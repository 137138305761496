.col-md-9 {
   margin-top: auto;
}

td{
  font-family : 'HPSimplified';
  font-size: 14px;
}

.sty{
  font-family : 'HPSimplified';
}

.form-control{
   font-family: 'HPSimplified';
   font-size: 14px;
}

.input-group-prepend, .btn{
   font-family: 'HPSimplified';
}

.tran{
   transition: 5s;
}

.nav-link{
   background: white;
}

label{
   font-weight: bold;
}

.form-inline{
  font-family: 'HPSimplified';
}


.container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}


div.col-md-12{
   color: #212529;
   font: 14px HPSimplified,Arial;
   padding:0px 15px;
}

span#ctl00_SubTitle
{
   color: #212529;
   font: 18px HPSimplified,Arial;
}

span#ctl00_PageTitle{
  color: #212529;
  font: 40px HPSimplified,Arial;
}

div.row.slide-search.navbar.navbar-expand-md{
  color: #212529;
  font: 14px HPSimplified,Arial;
}

div.main-search.mt-md-0{
  color: #212529;
  font: 14px HPSimplified,Arial;
  padding: 30px 0px;
}

.main-input-hold, .main-input-hold .form-control {
  display: flex;
  /* position: relative; */
  /* -webkit-box-flex: 1; */
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 50%;
  margin-bottom: 0;
  border-radius: 0;
  border-width: 2px;
}

.slide-search .btn, .slide-search input {
  height: 46px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

input, textarea {
  border: 2px solid #ccc;
  border-radius: 0px;
  padding: 1rem .375rem;
}

.main-input-hold input[type='text'] {
  max-width: inherit;
}

h1{
  font: HPSimplified,Arial;
  font-size: 2.5rem;
}

body {
  margin: 0;
  font-family: HPSimplified,Arial !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}


/* .country-container .dropdown-item {
  display: block;
  width: 80%;
  padding: .25rem 0.4rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
} */

.country-container .dropdown-menu{
  overflow: overlay;
  height: 60vh;
}


.loader-image{
  opacity: 1 !important;
  z-index: 999;
}
/* 
.card-header {
  font-family: HPSimplified , Arial;
  background:#767676 !important;
  color:white;
} */

.loader{
  opacity: 0.2;
}

.card{
  border-bottom: rgba(0,0,0,0.125) 1px solid !important;
}

@media only screen and (min-width : 1200px) {
  .container { max-width: 1380px; }
}


label {
  font: 14px HPSimplified,Arial;
  margin-top: .3rem;
}

.country-container .country-selector {
  background-color: #fff;
  color: #0096d6;
  border-radius: 0;
  float: right;
  width:180px; 
}

.country-container .btn-primary:hover {
  background-color: #fff !important ;
  color: #0096d6;
}

.btn-primary:hover{
  background-color: #0096D6;
}

.search.btn.btn-primary{
  background-color: #0096D6;
   border-color: #007bff;
}

.search.btn-primary:hover {  
  background-color: #0096D6;
}


button#dropdown-basic.country-selector.dropdown-toggle.search.btn.btn-primary{
  background-color: #0096D6;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
  color: #fff;
  background-color: #0096D6 !important;
}

.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #0096D6 !important;
  border-color: #0096D6;
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}


.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color:#0096D6!important;
}

.apply.btn.btn-primary{
  background-color: #0096D6;
   border-color: #007bff;
}

.reset.btn.btn-primary{
  background-color: #0096D6;
   border-color: #007bff;
}

.btn.btn-secondary {
  color: #fff;
  background-color: #0096d6;
  border-color: #007bff;
}

.btn.btn-secondary:hover {
  color: #fff;
  background-color: #0096D6;
  border-color: #007bff;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color:#0096d6 !important;
  border-color: #007bff;
}

.btn-secondary.focus, .btn-secondary:focus {
  color: #fff;
  background-color: #0096d6 !important;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(130,138,145,.5);
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0096D6 !important;
  /* border-color: #007bff; */
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: white!important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color:#f2f2f2!important;
}


.table thead th {
  background:#B6B8B9;
  font-family: HPSimplified;
  color: black;
  font-size: 14px;
}

.alert-dark {
  font-family: HPSimplified ,Arial;
    background-color: #767676 !important;
    color:white !important;
}

.apply.btn.btn-primary{
  border-radius: 0 4px;
}

.reset.btn.btn-primary{
  border-radius: 0 4px;
}

.alert.alert-primary{
  background-color: #0096d6;
  color: white;
}

.alert.alert-success{
  background-color: #e7e7e7;
  color: black;
}

.alert{
  background: #e9ecef;
}

.responsive {
  width: 100%;
  height: auto;
  max-width: 600px;
}

.pagination {
  float: right;
}

.underline {
  text-decoration: underline;
}

.center-element{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-print-friendly
{
  /* float: right; */
  /* margin-left: 42.5rem; */
  display: flex; 
  justify-content: flex-end;
  height: 2rem;
  min-height: auto;
}
.btn-back-to-previous
{
  /* float: right; */
  display: flex; 
  justify-content: flex-end;
  height: 2rem;
  min-height: auto;
  /* min-height: auto; */
}
.go-to-top-button
{
  position: fixed; 
  width: 3%;
  left: 50%;
  bottom: 10px;
  height: 30px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  text-align: center;
}
