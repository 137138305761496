.navbar {
    background-color: #0096D6;
  }

  .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: white;
  }

  .nav-link {
    display: block;
    padding: .5rem 1rem;
}

.nav.ml-auto{
   width: fit-content;
}

a.nav-link{
  font: 18px HPSimplified,Arial;
  margin: 0px 35px 0px  0px;
  background: none;
}

.nav-link{
  background: white;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: silver;
}


.navbar {
  padding: .2rem 1rem;
  padding-top: 0.2rem;
  padding-right: 1rem;
  padding-bottom: 0.2rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}


@media only screen and (min-width : 1200px) {
  .container { max-width: 1380px; }
}

.navbar1{
  padding: .2rem 1rem;
  padding-top: 0.2rem;
  padding-right: 1rem;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
#lblCartCount {
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -10px; 
}