
/* .App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */


 
/* .ProductListtext {
  position: relative;
  top: -47px;
  background: white;
  padding: 7px;
  width: 106px;
  color: blue;
}

.dropdown:hover>.dropdown-menu{
  display: block;
} */

/* .navbar {
  height: 72px;
}

.nav-item{
  width: 150px;
  font-family:  'HP Simplified', Arial ,Verdana, Helvetica, Sans-serif;
  font-size: 14px;
} */

/* .navbar-nav > li {
  float: left;
  position: relative;
}
.navbar-light .navbar-nav .active a::after {
  border-bottom: 5px solid #5bc0eb;
  bottom: -10px;
  content: " ";
  left: 0;
  position: absolute;
  right: 0;
} */
/* 
.background {
  background-color: white;
  border-bottom: 1px solid #b9b8bb;
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9996;
}

.line a:hover{
  border-bottom: 1px solid  rgb(27, 144, 216);
  border-bottom-width: 3px;
  font-style:'HP Simplified' ;
} */

/* .form-control{
  width: 25%;
  
} */

/* 
.banner{
  position: relative;
  width: 90%;
  margin: 0 auto;
} */
/* 
.heading{
  color:azure;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: left;
  font-size: 20px;
  text-shadow: 5px 5px 10px silver;
}

.box{
  position:relative;
}

.img-title{
  position: absolute;
  color: #fff;
  text-align: center;
  left: 0;
  right: 0;
  top: 30%;
  letter-spacing: 3px;
}

.a{
  margin-left: -60%;
} */

@media screen {
  #printSection {
      display: none;
  }
}
@media print {
  body * {
      visibility:hidden;
  }
  #printSection, #printSection * {
      visibility:visible;
  }
  #printSection {
      position:absolute;
      left:0;
      top:0;
  }
}

/* .modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
} */

